import { ProductMadeInUsa } from '../SearchResults/products/ProductMadeInUsa';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import clsx from 'clsx';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import useDictionary from 'src/hooks/useDictionary';
import { useContext, useEffect, useState } from 'react';
import ComponentContext from 'lib/context/ComponentContext';
import { ProductLink } from '../SearchResults/products/ProductLink';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { PspProductRootProps } from '../SearchResults/PspProductRoot';
import { ProductInventoryMessage } from '../SearchResults/products/ProductInventoryMessage';
import { ProductPrice } from '../SearchResults/products/ProductPrice';
import { ProductMoreOptions } from '../SearchResults/products/ProductMoreOptions';
import { ProductAutoship } from '../SearchResults/products/ProductAutoship';
import BVPLPRating from 'components/BazarVoice/BVPLPRating';
import useOcCart from 'src/hooks/useOcCart';
import { RecommendationWidget } from '@sitecore-search/react/dist/esm/types';
import ProductCardTailwindVariant from './ProductCardTailwindVariant';
import { getProductPriceFromSearch } from 'src/helpers/ProductPriceUI';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from '../SearchResults/types';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
  getProductPromoTag,
  getTruncatedProductPromoTag,
} from 'src/helpers/Constants';
import { useProductInventory } from '../SearchResults/use-product-inventory';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import { myFavoriteVariants } from 'tailwindVariants/components/myFavoriteTailwindVariant';
import { productDetailTailwindVariant } from 'tailwindVariants/components/productDetailTailwindVariant';
import { useRouter } from 'next/router';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';

type ProductProps = Omit<PspProductRootProps, 'actions'> & {
  actions: RecommendationWidget['ActionProps'];
  title?: string;
};

// type InitialState = RecommendationInitialState<'itemsPerPage'>;
export const ProductCard = ({ product, actions, index }: ProductProps) => {
  const {
    addToFavoritesWrapper,
    addToFavoriteIcon,
    sitecoreProductRoot,
    productInfoWrapper,
    sitecoreProductName,
    submitBtn,
    productBottomWrapper,
    productImage,
    productTopWrapper,
    sitecoreImageWrapper,
    productStockPriceInfo,
    sitecoreImage,
  } = ProductCardTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const { saleLabel, offerLabel } = myFavoriteVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  //Getting an image from the product xp values and sorting the images
  const [isPickUpSelected, setIsPickUpSelected] = useState<boolean>();
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean | string>();
  const { push } = useRouter();

  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const { addToCart } = useOcCart();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useOcDispatch();

  const image = getFirstProductImage(product?.xp?.Images);
  const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);
  const wishlistData = useOcSelector((s) => s?.wishlist?.wishlist);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  // const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  // const pickup: boolean = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);
  const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);

  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );

  //Getting the product price
  const productPriceForPLP = getProductPriceFromSearch(product);
  const inventoryStatus = useProductInventory(product);

  //Adding the current product into user wishlist
  const addToFavoriteClick = (product: ProductSearchResultModelWithVariants, index: number) => {
    const productId = product?.id;
    if (productId) {
      if (isAnonymous) {
        setcomponentContextData({
          ...componentContextData,
          ToggleLoginOverlayForm: true,
          productToAddInWishlistAfterLogin: product,
          productIdToAddInWishlistAfterLogin: productId,
          clickFrom: GTMLabels?.featuredItems,
        });
      } else {
        dispatch(
          updateProductInWishlist({
            product: product,
            index: index,
            clickFrom: GTMLabels?.featuredItems,
            storeId: myStoreData?.storeId,
            currentPage: GTMLabels?.featuredItems,
            pageItem: GTMLabels?.featuredItems,
          })
        );
      }
    }
  };

  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod;
    setIsPickUpSelected(newMethod === FulfillmentType.DFS);
  }, [fulfillmentMethod, selectedStore?.storeId, componentContextData?.isDFSMethodChanged]);

  const ecommerceDisabled = myStoreData?.ecommerceDisabled;

  const addToCartDisabled = ecommerceDisabled === 1 || inventoryStatus === 'OutOfStock';

  //pushing GTM data
  const addGTMData = () => {
    sendProductsPromotion({
      eventName: GTM_EVENT?.selectItem,
      data: product as ProductSearchResultModelWithVariants,
      currentPage: GTMLabels?.featuredItems,
      pageItem: GTMLabels?.featuredItems,
      position: index,
    });
    window && window?.sessionStorage?.setItem(GTMLabels?.position, index?.toString());
  };
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;
  const promoTag =
    product?.xp?.PromoTag &&
    getProductPromoTag(product?.xp?.PromoTag, myStoreData?.dataareaid, myStoreData.priceGroupArray);
  return (
    <div key={product.id} className={clsx(sitecoreProductRoot({ className: 'h-full' }), '!h-full')}>
      {showOfferTooltip == product.id && (
        <div
          className={topTooltipWrapper({
            className: '!bottom-[85%] !left-1/2 -translate-x-1/2',
          })}
        >
          <div className={tooltipContainer()}>
            <div className={topTooltipArrowFirstDiv()}></div>
            <div className={topTooltipArrowSecondDiv()}></div>
            <p className={topToolTipDescription({ className: 'text-center' })}>{promoTag}</p>
          </div>
        </div>
      )}
      <div
        className={clsx(sitecoreProductRoot({ className: '!border-0 !p-0' }))}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation(), addGTMData();
          push(getProductDetailPath({ ...product, ParentID: product?.parentid }));
        }}
      >
        <div className={clsx(productTopWrapper({ className: 'relative' }))}>
          <ProductMadeInUsa product={product} />
          {/* On Sale Tag: */}
          {product?.xp?.OnSale && (
            <span
              className={saleLabel({ className: 'absolute top-[150%]' })}
              onClick={(e) => {
                e?.preventDefault();
                e.stopPropagation();
              }}
            >
              {getDictionaryValue('Sale')}
            </span>
          )}
          {/* <span>[Add to Favorite Icon]</span> */}
          <button
            aria-label="add to favorites"
            className={addToFavoritesWrapper()}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event?.stopPropagation();
              addToFavoriteClick(product || '', index);
            }}
          >
            <IconHelper
              className={addToFavoriteIcon({
                className: '[&>svg]:!h-[16px]',
              })}
              icon={
                !isAnonymous && wishlistData && isProductInWishlist(wishlistData, product.id || '')
                  ? 'heart'
                  : 'heart-stroke'
              }
            />
          </button>
        </div>
        {/* <div className={styles['sitecore-image-wrapper']}> */}
        <ProductLink
          {...{ actions, product, index }}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            event.stopPropagation(), addGTMData();
          }}
        >
          <div className={sitecoreImage()}>
            <div className={sitecoreImageWrapper()}>
              <ImageHelper
                className={productImage()}
                field={{
                  value: {
                    src: image?.Url || dummyProductImage?.jsonValue?.value?.src,
                    alt: product?.name || dummyProductImage?.jsonValue?.value?.alt,
                    width: 140,
                    height: 140,
                  },
                }}
              />
            </div>
            {promoTag && (
              <div
                className={offerLabel()}
                onMouseEnter={() => {
                  if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                    setShowOfferTooltip(product.id);
                  }
                }}
                onMouseLeave={() => {
                  if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                    setShowOfferTooltip(false);
                  }
                }}
              >
                {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
              </div>
            )}
          </div>
        </ProductLink>
        {product?.xp?.RetailUnit && (
          <div className="product-weight-container relative">
            <p className="absolute pr-3 pl-1 bottom-[100px] right-[0px] h-auto text-left bg-color-brand-primary-1-base text-color-text-white product-weight-clip">
              {product?.xp?.RetailUnit} {product?.xp?.RetailMeasure}
            </p>
          </div>
        )}
        {/* Product Tile Info */}
        <ProductLink {...{ actions, product, index }} styles={sitecoreProductName()}>
          <h2 onClick={addGTMData}>{product.name}</h2>
        </ProductLink>
      </div>
      <div className={productInfoWrapper()}>
        {/* Product Tile Middle  */}
        <div className={productStockPriceInfo()}>
          <ProductInventoryMessage product={product} />
          <ProductMoreOptions {...{ actions, product, index }} />
          <ProductPrice productPrice={productPriceForPLP} />
        </div>
        {/* Product Tile Bottom */}
        <div className={productBottomWrapper()}>
          <ProductAutoship product={product} />
          <div>
            <button
              aria-label="add to cart"
              disabled={addToCartDisabled as boolean}
              onClick={async () => {
                const response = await addToCart({
                  productId: product.id as string,
                  quantity: 1,
                  ID: `${product.id}`, // To prevent racing condition
                });
                if (response === null || (typeof response === 'object' && 'error' in response)) {
                  setHeaderContextData({
                    ...headerContextData,
                    showMiniCartLoader: false,
                    outOfStockProductIds: [product.id as string],
                  });
                } else {
                  setHeaderContextData({
                    ...headerContextData,
                    showMiniCartLoader: false,
                    outOfStockProductIds: [''],
                  });
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                sendProductsPromotion({
                  eventName: GTM_EVENT?.addToCart,
                  data: product as ProductSearchResultModelWithVariants,
                  currentPage: GTMLabels?.featuredItems,
                  pageItem: GTMLabels?.featuredItems,
                  position: index,
                  click_from: GTMLabels?.featuredItems,
                  storeId: selectedStore?.storeId,
                  fulfillment_option: isPickUpSelected ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                });
              }}
              className={clsx(
                submitBtn({ outlineCta: false }),
                'font-heading-desk-medium-bold',
                'text-heading-desk-medium-bold',
                'leading-heading-desk-medium-bold'
              )}
            >
              {productPriceForPLP?.hasImapPrice
                ? getDictionaryValue('ViewPriceInCartPDP')
                : getDictionaryValue('AddToCartCTAPDP')}
            </button>
          </div>
          {/* Ratings to be placed here */}
          <BVPLPRating
            bvProductId={product?.xp?.UPC}
            bvRedirectUrl={getProductDetailPath({ ...product, ParentID: product?.parentid })}
          />
        </div>
      </div>
    </div>
  );
};
